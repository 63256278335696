<template>
    <div class="action-schedule">
        <template v-if="(me.gender && message.mtype.includes('-a')) || message.mtype.includes('-b')">
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span>약속잡기를 시작합니다!</span>
                </div>
                <div class="message-content">
                    <div v-if="isAtype" class="desc">
                        먼저 일정을 입력해주세요. 일정을 입력하시면 남성분에게 전달됩니다.

                        <p class="desc-detail m-t-9">
                            *유의사항* 48시간 이내({{ scheduleDate }}까지)에 약속 잡기를 시작하지 않으면 만남 의사가
                            없는 것으로 간주하고 약속이 자동 취소처리되며, 미진행 수수료 2만원이 부과됩니다.
                        </p>
                    </div>
                    <div v-else class="desc">
                        여성분이 가능한 일정을 보내주셨어요. 일정과 지역을 정해주세요.
                        <p class="desc-detail m-t-9">
                            *유의사항* 48시간 이내({{ scheduleDate }}까지)에 약속 잡기를 시작하지 않으면 만남 의사가
                            없는 것으로 간주하고 약속이 자동 취소처리되며, 미진행 수수료 2만원이 부과됩니다.
                        </p>
                    </div>
                    <div class="grey-box m-b-8">
                        <div class="user-profile">
                            <img :src="photoUrl" alt="" />
                            <div class="profile-info">
                                <div class="age" v-html="age" />
                                <div class="region" v-html="content.region" />
                                <div class="job" v-html="content.job" />
                            </div>
                        </div>
                    </div>
                    <div class="grey-box m-b-8">
                        <ScheduleProgress :mode="isAtype ? 'a' : 'b'" :selected="1" />
                    </div>
                </div>
                <!-- 분기처리 추가 -->
                <div v-if="userRatePlans.onGoing" class="m-t-8 m-b-16">
                    <!-- <img class="m-r-4" src="@/assets/images/icons/info_pink.png" width="16px" height="16px" />

                    <div class="text" v-html="'약속 확정시 매칭권 1회가 사용됩니다'" /> -->
                </div>
            </div>
            <BottomButton
                :disabled="disabled"
                @click="onClickEnterSchedule"
                :label="`약속잡기 시작`"
                :nonFixed="true"
            />
        </template>
        <template v-else>
            <div class="message">
                <div class="message-top m-b-16">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span>매칭을 축하드립니다!</span>
                </div>
                <div class="message-content">
                    <div class="m-b-8">
                        <span>'{{ content.job }}/{{ content.age }}/{{ content.region }}'</span> 회원과 매칭 되었어요!
                    </div>
                    <div>현재 여성분이 일정을 선택하고 있어요. 선택이 완료되면 다시 안내드릴게요:)</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ScheduleProgress from './ScheduleProgress'

export default {
    name: 'ActionScheduleStartA',
    props: ['message'],
    components: {
        ScheduleProgress,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        createdAt() {
            return this.message.created_at
        },
        photoUrl() {
            if (!this.me.gender && this.message.mtype.includes('-a')) return null
            return this.content.urls[0] || null
        },
        age() {
            return this.$options.filters.asAgeInActionMessage(this.content.birthday)
        },
        isAtype() {
            return this.message.mtype === 'schedule-start-a'
        },
        disabled() {
            return this.content.status === 1
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
        validTickets() {
            return this.$store.getters.validTickets
        },
        mannerTickets() {
            return this.$store.getters.mannerTickets.filter(t => t.enabled)
        },
        PostpaidRatePlan() {
            if (
                this.userRatePlans.onGoingTicketPlan.find(o => o.ptype === 'rate_plan' && o.code.includes('unlimited'))
            ) {
                return this.$store.getters.products.all.find(
                    item =>
                        item.id ===
                        this.userRatePlans.onGoingTicketPlan.find(
                            o => o.ptype === 'rate_plan' && o.code.includes('unlimited')
                        ).product_id
                )
            }
            return false
        },
        selectPostpaidTicket() {
            if (!this.PostpaidRatePlan || !this.PostpaidRatePlan.code) return {}

            return this.me.tickets.find(item => item.enabled === true)
        },
        hasUnpaid() {
            return (this.me || {}).products.some(p => {
                return p.product_id === 62
            })
        },
        scheduleDate() {
            const createdAt = new Date(this.createdAt)
            const targetDate = new Date(createdAt.getTime() + 48 * 60 * 60 * 1000)

            let month = targetDate.getMonth() + 1
            let day = targetDate.getDate()
            let hour = targetDate.getHours()

            month = month < 10 ? '0' + month : month
            day = day < 10 ? '0' + day : day
            hour = hour < 10 ? '0' + hour : hour

            const formattedDate = `${month}월 ${day}일 ${hour}시`

            console.log('date', targetDate)

            return formattedDate
        },
    },
    methods: {
        onClickEnterSchedule() {
            if (
                !(this.userRatePlans.onGoingTicketPlan.length > 0) &&
                !(this.userRatePlans.onGoing.length > 0 && this.validTickets.length > 0) &&
                !(this.PostpaidRatePlan && this.PostpaidRatePlan.code.includes('unlimited')) &&
                !this.hasUnpaid &&
                !this.content.manner_like &&
                !this.content.is_michagam
            ) {
                this.$modal
                    .basic({
                        body: `매칭권이 없습니다. 매칭권을 구매하면 횟수 제한 없이 마음에 드는 이성의 프로필을 수락할 수 있고, 실제 약속까지 잡아드려요:)<br/>특가 할인 중이니 혜택을 놓치지 마세요!`,
                        buttons: [
                            // {
                            //     label: 'CANCEL',
                            //     class: 'btn-default',
                            // },
                            {
                                // label: 'CONFIRM',
                                label: '할인가 보기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        // if (idx === 1) {
                        if (idx === 0) {
                            console.log('134: ', this.selectPostpaidTicket)
                            // this.$store.commit('setSelectedProduct', this.selectPostpaidTicket)
                            this.$router.push({
                                name: 'PrePaymentPage',
                                // params: { categoryName: this.category.category },
                            })
                        }
                    })
            } else {
                if (
                    this.PostpaidRatePlan &&
                    this.PostpaidRatePlan.plan_type === 'postpaid' &&
                    this.validTickets.length === 0 &&
                    !this.content.is_michagam
                ) {
                    // 후불제 product_id 조건 임시로 한건데 onGoing product 의 plan_type === 'postpaid' 인지 확인해야함
                    this.$modal
                        .basic({
                            body: `매칭권이 없습니다. 매칭권을 구매하면 횟수 제한 없이 마음에 드는 이성의 프로필을 수락할 수 있고, 실제 약속까지 잡아드려요:)<br/>특가 할인 중이니 혜택을 놓치지 마세요!`,
                            buttons: [
                                // {
                                //     label: 'CANCEL',
                                //     class: 'btn-default',
                                // },
                                {
                                    // label: 'CONFIRM',
                                    label: '할인가 보기',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx === 0) {
                                console.log('134: ', this.selectPostpaidTicket)
                                this.$store.commit('setSelectedProduct', this.selectPostpaidTicket)
                                this.$router.push({
                                    name: 'TicketPaymentMethodsPage',
                                    // params: { categoryName: this.category.category },
                                })
                            }
                        })
                } else {
                    if (
                        (this.isAtype && this.content.manner_like === 'b_user') ||
                        (!this.isAtype && this.content.manner_like === 'a_user')
                    ) {
                        this.$modal
                            .basic({
                                title: '매너 수락',
                                body:
                                    '상대방이 ‘매너 수락’을 하여 매칭권을 사용하지 않고 약속을 진행할 수 있습니다. <br>참고로, 매너 수락을 위해 상대방이 추가 비용을 부담하였습니다. <br>진정성 있는 만남 부탁드립니다 :)',
                                buttons: [
                                    {
                                        label: 'CANCEL',
                                        class: 'btn-default',
                                    },
                                    {
                                        label: 'CONFIRM',
                                        class: 'btn-primary',
                                    },
                                ],
                            })
                            .then(idx => {
                                if (idx === 1) {
                                    this.$stackRouter.push({
                                        name: 'PromisePage',
                                        props: {
                                            message: this.message,
                                            isAtype: this.isAtype,
                                            userInfo: {
                                                message: this.message,
                                                photoUrl: this.photoUrl,
                                                age: this.age,
                                                region: this.content.region,
                                                job: this.content.job,
                                                schedule_id: this.content.schedule_id,
                                                date_confirmed: this.content.date_confirmed,
                                                meet_place: this.content.meet_place,
                                                meet_type_confirmed: this.content.meet_type_confirmed,
                                            },
                                        },
                                    })
                                }
                            })
                    } else if (
                        (this.isAtype && this.content.manner_like === 'a_user') ||
                        (!this.isAtype && this.content.manner_like === 'b_user')
                    ) {
                        if (this.mannerTickets.length > 0) {
                            console.log('123', this.$store.getters.products)
                            this.$modal
                                .basic({
                                    title: '매너 수락',
                                    body: '이미 보유한 매너수락권을 사용해 매칭을 진행합니다.',
                                    buttons: [
                                        {
                                            label: 'CANCEL',
                                            class: 'btn-default',
                                        },
                                        {
                                            label: 'CONFIRM',
                                            class: 'btn-primary',
                                        },
                                    ],
                                })
                                .then(idx => {
                                    if (idx === 1) {
                                        if (
                                            !this.PostpaidRatePlan &&
                                            this.PostpaidRatePlan.code !== 'unlimited_female' &&
                                            this.PostpaidRatePlan.code !== 'unlimited_male' &&
                                            this.validTickets.length === 0 &&
                                            !this.content.is_michagam
                                        ) {
                                            this.$modal
                                                .basic({
                                                    body: `매칭권이 없습니다. 매칭권을 구매하면 횟수 제한 없이 마음에 드는 이성의 프로필을 수락할 수 있고, 실제 약속까지 잡아드려요:)<br/>특가 할인 중이니 혜택을 놓치지 마세요!`,
                                                    buttons: [
                                                        // {
                                                        //     label: 'CANCEL',
                                                        //     class: 'btn-default',
                                                        // },
                                                        {
                                                            // label: 'CONFIRM',
                                                            label: '할인가 보기',
                                                            class: 'btn-primary',
                                                        },
                                                    ],
                                                })
                                                .then(idx => {
                                                    // if (idx === 1) {
                                                    if (idx === 0) {
                                                        this.$router.push({
                                                            name: 'PrePaymentPage',
                                                        })
                                                    }
                                                })
                                        } else {
                                            this.$stackRouter.push({
                                                name: 'PromisePage',
                                                props: {
                                                    message: this.message,
                                                    isAtype: this.isAtype,
                                                    userInfo: {
                                                        message: this.message,
                                                        photoUrl: this.photoUrl,
                                                        age: this.age,
                                                        region: this.content.region,
                                                        job: this.content.job,
                                                        schedule_id: this.content.schedule_id,
                                                        date_confirmed: this.content.date_confirmed,
                                                        meet_place: this.content.meet_place,
                                                        meet_type_confirmed: this.content.meet_type_confirmed,
                                                    },
                                                },
                                            })
                                        }
                                    }
                                })
                        } else {
                            if (
                                !this.PostpaidRatePlan &&
                                this.PostpaidRatePlan.code !== 'unlimited_female' &&
                                this.PostpaidRatePlan.code !== 'unlimited_male' &&
                                this.validTickets.length === 0 &&
                                !this.content.is_michagam
                            ) {
                                console.log('AAA')
                                this.$modal
                                    .basic({
                                        body: `매칭권이 없습니다. 매칭권을 구매하면 횟수 제한 없이 마음에 드는 이성의 프로필을 수락할 수 있고, 실제 약속까지 잡아드려요:)<br/>특가 할인 중이니 혜택을 놓치지 마세요!`,
                                        buttons: [
                                            // {
                                            //     label: 'CANCEL',
                                            //     class: 'btn-default',
                                            // },
                                            {
                                                // label: 'CONFIRM',
                                                label: '할인가 보기',
                                                class: 'btn-primary',
                                            },
                                        ],
                                    })
                                    .then(idx => {
                                        // if (idx === 1) {
                                        if (idx === 0) {
                                            this.$router.push({
                                                name: 'PrePaymentPage',
                                            })
                                        }
                                    })
                                return
                            }
                            this.$modal
                                .basic({
                                    title: '매너 수락',
                                    body:
                                        '매너 수락 비용 3만원을 결제해주세요. 매너 수락 비용 결제 이후에 매칭권을 사용합니다.',
                                    buttons: [
                                        {
                                            label: 'CANCEL',
                                            class: 'btn-default',
                                        },
                                        {
                                            label: '결제하기',
                                            class: 'btn-primary',
                                        },
                                    ],
                                })
                                .then(idx => {
                                    if (idx === 1) {
                                        const selectMannerTicket = this.$store.getters.products.ticket_plan.find(
                                            item => item.code === `ticket_manner_like`
                                        )
                                        this.$store.commit('setSelectedProduct', selectMannerTicket)
                                        this.$store.commit('setSelectedPlan', selectMannerTicket)
                                        this.$router.push({
                                            name: 'PaymentMethodsPage',
                                            // params: { categoryName: this.category.category },
                                        })
                                    }
                                })
                        }
                    } else {
                        this.$stackRouter.push({
                            name: 'PromisePage',
                            props: {
                                message: this.message,
                                isAtype: this.isAtype,
                                userInfo: {
                                    message: this.message,
                                    photoUrl: this.photoUrl,
                                    age: this.age,
                                    region: this.content.region,
                                    job: this.content.job,
                                    schedule_id: this.content.schedule_id,
                                    date_confirmed: this.content.date_confirmed,
                                    meet_place: this.content.meet_place,
                                    meet_type_confirmed: this.content.meet_type_confirmed,
                                },
                            },
                        })
                    }
                }
            }
            if (this.content.is_michagam) {
                this.$modal.basic({
                    body: '매칭권 소모 없이 진행하는 약속입니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            }
        },
    },
}
</script>

<style scoped lang="scss">
.info {
    background: rgba(255, 61, 107, 0.07);
    border-radius: 8px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #111111;
    display: flex;
    align-items: center;
}
.message-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
    span {
        font-weight: 700;
    }
    .desc {
        margin-bottom: 8px;
    }
    .desc-detail {
        margin: 0 12px 8px 12px;
        color: #f64f56;
        font-size: 10px;
        line-height: 19px;
        font-style: italic;
    }
}
</style>
